.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: 198px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #191c1f;
}
.footer-column {
  flex: 0 0 auto;
  width: var(--dl-size-size-maxwidth);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.footer-text {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  width: 65%;
  align-self: flex-end;
  line-height: 1.5;
}
.footer-row {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
}
.footer-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.footer-image {
  height: 2.5rem;
}
.footer-text01 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.footer-stores {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
}
.footer-link {
  display: contents;
}
.footer-image1 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.footer-link01 {
  display: contents;
}
.footer-image2 {
  width: 168px;
  height: 55px;
  margin: var(--dl-space-space-halfunit);
  object-fit: contain;
  text-decoration: none;
}
.footer-links-container {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  flex-wrap: wrap;
}
.footer-product-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text02 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink4 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink5 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-decoration: none;
}
.footer-company-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text03 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-link02 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link03 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink6 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink7 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink8 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-decoration: none;
}
.footer-location-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text04 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text05 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text11 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text12 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
}
.footer-contact-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text13 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-link04 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link05 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-decoration: none;
}
.footer-link06 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.footer-socials {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text18 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link07 {
  display: contents;
}
.footer-icon {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link08 {
  display: contents;
}
.footer-icon2 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link09 {
  display: contents;
}
.footer-icon4 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.footer-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.footer-link10 {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  align-self: center;
  text-decoration: none;
}











@media(max-width: 1600px) {
  .footer-footer {
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .footer-column {
    width: auto;
  }
  .footer-text {
    width: 100%;
  }
  .footer-row {
    justify-content: space-between;
  }
}
@media(max-width: 1200px) {
  .footer-footer {
    padding-top: 90px;
  }
  .footer-text {
    fill: var(--dl-color-gray-500);
    color: var(--dl-color-gray-500);
    width: 100%;
    line-height: 1.5;
  }
  .footer-row {
    flex-direction: row;
  }
}
@media(max-width: 991px) {
  .footer-footer {
    padding-top: 90px;
    flex-direction: column;
  }
  .footer-row {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .footer-logo {
    align-items: center;
    justify-content: center;
  }
  .footer-stores {
    align-self: center;
    align-items: center;
    flex-direction: row;
  }
  .footer-links-container {
    width: auto;
    flex-direction: row;
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-top: 90px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-row {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-text01 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    text-align: center;
    margin-bottom: 0px;
  }
  .footer-stores {
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-text {
    margin-top: var(--dl-space-space-sixunits);
  }
  .footer-row {
    align-self: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .footer-image {
    margin-bottom: 0px;
  }
  .footer-text01 {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-stores {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .footer-image1 {
    width: 150px;
  }
  .footer-image2 {
    width: 125px;
  }
  .footer-contact-container {
    height: auto;
    align-self: flex-start;
    align-items: flex-start;
  }
  .footer-contact {
    height: auto;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
  }
  .footer-link10 {
    font-size: 14px;
    text-align: center;
  }
}
