/* Add this CSS to your cash-over-fees.css file */

.cash-over-fees-container02 table,
.cash-over-fees-container04 table,
.cash-over-fees-container06 table,
.cash-over-fees-container07 table,
.cash-over-fees-container08 table,
.cash-over-fees-container10 table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px; /* Adjust margin as needed */
  height: 100%;
}

.cash-over-fees-container02 td,
.cash-over-fees-container04 td,
.cash-over-fees-container06 td,
.cash-over-fees-container07 td,
.cash-over-fees-container08 td,
.cash-over-fees-container10 td {
  border: 1px solid #d3d3d3;
  text-align: center;
  font-family: Tahoma;
  color: #616161;
  width: 33.33%; /* Each cell takes one-third of the table width */
  height: 50px; /* Set a fixed height for all cells */
}

.cash-over-fees-container02 tr:first-child td,
.cash-over-fees-container04 tr:first-child td,
.cash-over-fees-container06 tr:first-child td,
.cash-over-fees-container07 tr:first-child td,
.cash-over-fees-container08 tr:first-child td,
.cash-over-fees-container10 tr:first-child td {
  border-top: none;
}

.cash-over-fees-container02 tr:last-child td,
.cash-over-fees-container04 tr:last-child td,
.cash-over-fees-container06 tr:last-child td,
.cash-over-fees-container07 tr:last-child td,
.cash-over-fees-container08 tr:last-child td,
.cash-over-fees-container10 tr:last-child td {
  border-bottom: none;
}

.cash-over-fees-container02 tr td:first-child,
.cash-over-fees-container04 tr td:first-child,
.cash-over-fees-container06 tr td:first-child,
.cash-over-fees-container07 tr td:first-child,
.cash-over-fees-container08 tr td:first-child,
.cash-over-fees-container10 tr td:first-child {
  border-left: none;
}

.cash-over-fees-container02 tr td:last-child,
.cash-over-fees-container04 tr td:last-child,
.cash-over-fees-container06 tr td:last-child,
.cash-over-fees-container07 tr td:last-child,
.cash-over-fees-container08 tr td:last-child,
.cash-over-fees-container10 tr td:last-child {
  border-right: none;
}




.cash-over-fees-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.cash-over-fees-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  max-width: 100%;
  align-self: center;
  max-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.cash-over-fees-dummy {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cash-over-fees-column {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cash-over-fees-text {
  font-size: 48px;
  font-style: normal;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
}
.cash-over-fees-text01 {
  width: auto;
  max-width: 800px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  line-height: 1.5;
}
.cash-over-fees-row {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cash-over-fees-link {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.cash-over-fees-link1 {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-right-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cash-over-fees-link2 {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-left-width: 1px;
  border-right-width: 0px;
}
.cash-over-fees-link3 {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cash-over-fees-row1 {
  flex: initial;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.cash-over-fees-icon {
  fill: var(--dl-color-gray-black);
  width: 42px;
  height: 24px;
}
.cash-over-fees-text04 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  padding: 0px;
  align-self: center;
  margin-top: 0px;
}
.cash-over-fees-icon2 {
  fill: var(--dl-color-gray-black);
  width: 42px;
  height: 24px;
}
.cash-over-fees-personal {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.cash-over-fees-row2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.cash-over-fees-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.cash-over-fees-cash-over {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}
.cash-over-fees-text05 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-weight: 900;
}
.cash-over-fees-container01 {
  flex: initial;
  width: 100%;
  height: 800px;
  max-width: 800px;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
}
.cash-over-fees-container02 {
  display: contents;
}
.cash-over-fees-text06 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-weight: 900;
}
.cash-over-fees-container03 {
  width: 800px;
  height: 275px;
  margin-top: var(--dl-space-space-twounits);
}
.cash-over-fees-container04 {
  display: contents;
}
.cash-over-fees-text07 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-weight: 900;
}
.cash-over-fees-container05 {
  width: 100%;
  height: 1200px;
  max-width: 800px;
  margin-top: var(--dl-space-space-twounits);
}
.cash-over-fees-container06 {
  display: contents;
}
.cash-over-fees-text08 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-weight: 900;
}
.cash-over-fees-container07 {
  width: 100%;
  max-width: 800px;
  height: 1200px;
  margin-top: var(--dl-space-space-twounits);
}
.cash-over-fees-container08 {
  display: contents;

}
.cash-over-fees-merchant {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: auto;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.cash-over-fees-row3 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.cash-over-fees-image1 {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.cash-over-fees-cash-over1 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}
.cash-over-fees-text09 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 700px;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  line-height: 1.5;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.cash-over-fees-action2 {
  margin-top: var(--dl-space-space-twounits);
  text-decoration: none;
}
.cash-over-fees-business {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: auto;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.cash-over-fees-row4 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.cash-over-fees-image2 {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.cash-over-fees-cash-over2 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}
.cash-over-fees-container09 {
  width: 100%;
  height: 530px;
  max-width: 800px;
  margin-top: var(--dl-space-space-twounits);
}
.cash-over-fees-container10 {
  display: contents;
}
.cash-over-fees-sd-ks {
  flex: 0 0 auto;
  display: flex;
  max-width: auto;
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.cash-over-fees-row5 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.cash-over-fees-image3 {
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.cash-over-fees-cash-over3 {
  font-size: 32px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}
.cash-over-fees-text10 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-size: 16px;
  max-width: 700px;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  line-height: 1.5;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.cash-over-fees-navlink {
  text-decoration: underline;
}
@media(max-width: 1600px) {
  .cash-over-fees-text {
    font-size: 42px;
  }
  .cash-over-fees-row2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .cash-over-fees-image {
    width: 60px;
    height: 60px;
  }
  .cash-over-fees-cash-over {
    font-size: 28px;
  }
  .cash-over-fees-row3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .cash-over-fees-image1 {
    width: 60px;
    height: 60px;
  }
  .cash-over-fees-cash-over1 {
    font-size: 28px;
  }
  .cash-over-fees-business {
    width: 100%;
  }
  .cash-over-fees-row4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .cash-over-fees-image2 {
    width: 60px;
    height: 60px;
  }
  .cash-over-fees-cash-over2 {
    font-size: 28px;
  }
  .cash-over-fees-sd-ks {
    width: 100%;
  }
  .cash-over-fees-row5 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .cash-over-fees-image3 {
    width: 60px;
    height: 60px;
  }
  .cash-over-fees-cash-over3 {
    font-size: 28px;
  }
}
@media(max-width: 1200px) {
  .cash-over-fees-text {
    font-size: 38px;
  }
  .cash-over-fees-text01 {
    width: auto;
  }
  .cash-over-fees-cash-over {
    font-size: 26px;
  }
  .cash-over-fees-container03 {
    width: 100%;
    max-width: 800px;
  }
  .cash-over-fees-container05 {
    height: 1200px;
  }
  .cash-over-fees-cash-over1 {
    font-size: 26px;
  }
  .cash-over-fees-cash-over2 {
    font-size: 26px;
  }
  .cash-over-fees-cash-over3 {
    font-size: 26px;
  }
}
@media(max-width: 991px) {
  .cash-over-fees-hero {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .cash-over-fees-text {
    font-size: 32px;
  }
  .cash-over-fees-text01 {
    width: auto;
    max-width: 600px;
  }
}
@media(max-width: 767px) {
  .cash-over-fees-link {
    font-size: 14px;
  }
  .cash-over-fees-link1 {
    font-size: 14px;
  }
  .cash-over-fees-link2 {
    font-size: 14px;
  }
  .cash-over-fees-link3 {
    font-size: 14px;
  }
  .cash-over-fees-row2 {
    gap: var(--dl-space-space-unit);
  }
  .cash-over-fees-image {
    width: 40px;
    height: 40px;
  }
  .cash-over-fees-row3 {
    gap: var(--dl-space-space-unit);
  }
  .cash-over-fees-image1 {
    width: 40px;
    height: 40px;
  }
  .cash-over-fees-row4 {
    gap: var(--dl-space-space-unit);
  }
  .cash-over-fees-image2 {
    width: 40px;
    height: 40px;
  }
  .cash-over-fees-row5 {
    gap: var(--dl-space-space-unit);
  }
  .cash-over-fees-image3 {
    width: 40px;
    height: 40px;
  }
}
@media(max-width: 479px) {
  .cash-over-fees-text {
    font-size: 28px;
  }
  .cash-over-fees-text01 {
    font-size: 14px;
    margin-top: var(--dl-space-space-unit);
  }
  .cash-over-fees-row {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .cash-over-fees-link3 {
    font-size: 14px;
    text-decoration: none;
  }
  .cash-over-fees-text04 {
    font-size: 14px;
  }
  .cash-over-fees-personal {
    padding-left: 0px;
    padding-right: 0px;
  }
  .cash-over-fees-row2 {
    gap: var(--dl-space-space-halfunit);
  }
  .cash-over-fees-cash-over {
    font-size: 22px;
  }
  .cash-over-fees-container03 {
    flex: initial;
    height: 150px;
  }
  .cash-over-fees-row3 {
    gap: var(--dl-space-space-halfunit);
  }
  .cash-over-fees-cash-over1 {
    font-size: 22px;
  }
  .cash-over-fees-text09 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .cash-over-fees-row4 {
    gap: var(--dl-space-space-halfunit);
  }
  .cash-over-fees-cash-over2 {
    font-size: 22px;
  }
  .cash-over-fees-row5 {
    gap: var(--dl-space-space-halfunit);
  }
  .cash-over-fees-cash-over3 {
    font-size: 22px;
  }
  .cash-over-fees-text10 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
