.support-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.support-column {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.support-dummy {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.support-column1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.support-text {
  font-size: 5rem;
  font-style: normal;
  text-align: center;
  font-weight: 900;
}
.support-text1 {
  color: var(--dl-color-primary-300);
}
.support-text3 {
  font-size: 24px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
.support-row {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.support-action1 {
  display: flex;
  flex-direction: row;
}
.support-icon {
  width: 20px;
  height: 20px;
  margin-right: var(--dl-space-space-halfunit);
}
.support-text4 {
  align-self: center;
  text-align: center;
}
.support-action11 {
  display: flex;
  flex-direction: row;
}
.support-icon2 {
  width: 20px;
  height: 20px;
  margin-right: var(--dl-space-space-halfunit);
}
.support-text5 {
  align-self: center;
  text-align: center;
}
.support-action12 {
  display: flex;
  flex-direction: row;
}
.support-icon4 {
  color: currentColor;
  width: 20px;
  height: 20px;
  margin-right: var(--dl-space-space-halfunit);
}
.support-text6 {
  align-self: center;
  text-align: center;
}
.support-column2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.support-text7 {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.support-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.support-icon6 {
  width: 100px;
  height: 100px;
}
@media(max-width: 1600px) {
  .support-text3 {
    font-size: 22px;
  }
}
@media(max-width: 1200px) {
  .support-text {
    font-size: 4rem;
  }
  .support-text3 {
    font-size: 20px;
  }
}
@media(max-width: 991px) {
  .support-column {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .support-text {
    font-size: 3rem;
  }
}
@media(max-width: 767px) {
  .support-column {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .support-icon6 {
    width: 75px;
    height: 75px;
  }
}
@media(max-width: 479px) {
  .support-text {
    font-size: 2.5rem;
  }
  .support-text3 {
    font-size: 16px;
  }
  .support-row {
    flex-wrap: wrap;
  }
  .support-text7 {
    font-size: 14px;
  }
  .support-icon6 {
    width: 50px;
    height: 50px;
  }
}
