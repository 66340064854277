.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.page-column {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.page-text {
  font-size: 300px;
  font-weight: 900;
}
.page-row {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-icon {
  width: 24px;
  height: 24px;
}
.page-text1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.page-navlink {
  transition: 0.3s;
  text-decoration: none;
}
.page-navlink:hover {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  background-color: #000000;
}
@media(max-width: 1600px) {
  .page-column {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media(max-width: 1200px) {
  .page-text {
    font-size: 200px;
  }
  .page-icon {
    width: 20px;
    height: 20px;
  }
  .page-text1 {
    font-size: 20px;
  }
}
@media(max-width: 991px) {
  .page-text {
    font-size: 150px;
  }
}
@media(max-width: 767px) {
  .page-text {
    font-size: 125px;
  }
}
@media(max-width: 479px) {
  .page-text {
    text-align: center;
  }
  .page-row {
    gap: var(--dl-space-space-halfunit);
    align-items: flex-start;
  }
  .page-text1 {
    text-align: center;
  }
  .page-text2 {
    text-align: center;
  }
}
