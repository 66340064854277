.contact-form-light-contact11 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  overflow: hidden;
  position: relative;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.contact-form-light-max-width {
  display: flex;
  flex-direction: column;
}
.contact-form-light-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-direction: column;
}
.contact-form-light-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text01 {
  font-family: "Raleway";
}
.contact-form-light-content1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.contact-form-light-form {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-form-light-container {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  height: 100px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.contact-form-light-input {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text-input {
  align-self: stretch;
}
.contact-form-light-input1 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text-input1 {
  align-self: stretch;
}
.contact-form-light-input2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.contact-form-light-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-input3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-form-light-textarea {
  max-width: 700px;
}
.contact-form-light-checkbox {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.contact-form-light-button {
  align-self: flex-end;
  justify-content: center;
  align-content: center;
}
.loadingIndicator {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: relative;
  align-self: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contact-form-light-content2 {
  flex: 1;
  align-items: stretch;
}
.contact-form-light-row {
  align-items: flex-start;
}
.contact-form-light-content3 {
  flex: 1;
}
.contact-form-light-icon {
  width: 32px;
  height: 32px;
}
.contact-form-light-contact-info {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text09 {
  font-family: "Raleway";
}
.contact-form-light-email {
  text-decoration: none;
}
.contact-form-light-content4 {
  flex: 1;
}
.contact-form-light-icon2 {
  width: 32px;
  height: 32px;
}
.contact-form-light-contact-info1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text10 {
  font-family: "Raleway";
}
.contact-form-light-phone {
  text-decoration: none;
}
.contact-form-light-row1 {
  width: 419px;
  align-self: stretch;
}
.contact-form-light-content5 {
  flex: 1;
}
.contact-form-light-icon4 {
  width: 32px;
  height: 32px;
}
.contact-form-light-contact-info2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-light-text11 {
  font-family: "Raleway";
}
.contact-form-light-image {
  width: 100px;
  object-fit: cover;
}

@media (max-width: 1600px) {
  .contact-form-light-container {
    gap: var(--dl-space-space-unit);
    height: auto;
  }
  .contact-form-light-input {
    flex: 1;
  }
  .contact-form-light-input1 {
    flex: 1;
  }
}
@media (max-width: 991px) {
  .contact-form-light-form {
    width: 50%;
  }
  .contact-form-light-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .contact-form-light-container1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form-light-input3 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    flex-direction: column;
  }
  .contact-form-light-content2 {
    width: 50%;
  }
  .contact-form-light-content3 {
    width: 50%;
  }
  .contact-form-light-contact-info {
    width: 50%;
  }
  .contact-form-light-content4 {
    width: 50%;
  }
  .contact-form-light-row1 {
    width: 100%;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .contact-form-light-text01 {
    font-size: 28px;
  }
  .contact-form-light-input3 {
    width: 100%;
  }
  .contact-form-light-row1 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .contact-form-light-text01 {
    font-size: 26px;
  }
  .contact-form-light-content1 {
    flex-direction: column;
  }
  .contact-form-light-form {
    width: 100%;
  }
  .contact-form-light-input3 {
    width: 100%;
  }
  .contact-form-light-content2 {
    width: 100%;
  }
  .contact-form-light-row {
    flex-direction: column;
  }
  .contact-form-light-content3 {
    width: 100%;
  }
  .contact-form-light-content4 {
    width: 100%;
  }
  .contact-form-light-row1 {
    width: 100%;
    flex-direction: column;
  }
}
