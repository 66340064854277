.faq-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.faq-column {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.faq-column1 {
  width: 100%;
  height: 500px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 1400px;
  align-items: flex-end;
  border-radius: 35px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0b6ef9;
}
.faq-column2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-text {
  fill: var(--dl-color-custom-secondary1);
  color: var(--dl-color-custom-secondary1);
  font-size: 56px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  line-height: 1.5;
  text-transform: uppercase;
}
.faq-text1 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: left;
  font-weight: 500;
}
.faq-icon {
  fill: #ffffff;
  width: 150px;
  height: 150px;
  align-self: flex-end;
}
.faq-text2 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.faq-text3 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.faq-text4 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.faq-text5 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 24px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
@media(max-width: 1600px) {
  .faq-column1 {
    max-width: 1200px;
  }
  .faq-text {
    font-size: 42px;
  }
  .faq-text1 {
    font-size: 18px;
  }
  .faq-text5 {
    color: var(--dl-color-primary-300);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
}
@media(max-width: 1200px) {
  .faq-column {
    width: 100%;
    align-self: center;
    align-items: center;
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .faq-column1 {
    width: 100%;
    height: 500px;
    padding: var(--dl-space-space-threeunits);
    max-width: 900px;
    align-self: center;
    align-items: flex-end;
  }
  .faq-text {
    font-size: 38px;
  }
  .faq-icon {
    width: 100px;
    height: 100px;
  }
}
@media(max-width: 991px) {
  .faq-column {
    padding: var(--dl-space-space-threeunits);
    margin-top: var(--dl-space-space-fourunits);
  }
  .faq-column1 {
    width: 100%;
    padding: var(--dl-space-space-fourunits);
  }
  .faq-text {
    font-size: 32px;
  }
  .faq-text1 {
    font-size: 16px;
  }
  .faq-icon {
    margin-top: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .faq-column {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .faq-column1 {
    padding: var(--dl-space-space-twounits);
  }
  .faq-text {
    font-size: 24px;
  }
  .faq-text1 {
    font-size: 14px;
  }
  .faq-icon {
    width: 75px;
    height: 75px;
  }
}
