.navigation-bar-home-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1;
  position: fixed;
  border-color: var(--dl-color-gray-black);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navigation-bar-home-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.navigation-bar-home-navlink {
  display: contents;
}
.navigation-bar-home-image1 {
  height: 2rem;
  text-decoration: none;
}
.navigation-bar-home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navigation-bar-home-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.navigation-bar-home-link1 {
  text-decoration: none;
}
.navigation-bar-home-link2 {
  text-decoration: none;
}
.navigation-bar-home-link3 {
  text-decoration: none;
}
.navigation-bar-home-link4 {
  text-decoration: none;
}
.navigation-bar-home-link6 {
  text-decoration: none;
}
.navigation-bar-home-link5 {
  text-decoration: none;
}
.navigation-bar-home-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-bar-home-action1 {
  text-decoration: none;
}
.navigation-bar-home-action2 {
  text-decoration: none;
}
.navigation-bar-home-burger-menu {
  display: none;
}
.navigation-bar-home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navigation-bar-home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navigation-bar-home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navigation-bar-home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navigation-bar-home-navlink1 {
  display: contents;
}
.navigation-bar-home-logo {
  height: 3rem;
  text-decoration: none;
}
.navigation-bar-home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-bar-home-icon02 {
  width: 24px;
  height: 24px;
}
.navigation-bar-home-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navigation-bar-home-link11 {
  text-decoration: none;
}
.navigation-bar-home-link21 {
  text-decoration: none;
}
.navigation-bar-home-link31 {
  text-decoration: none;
}
.navigation-bar-home-link41 {
  text-decoration: none;
}
.navigation-bar-home-link61 {
  text-decoration: none;
}
.navigation-bar-home-link51 {
  text-decoration: none;
}
.navigation-bar-home-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navigation-bar-home-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navigation-bar-home-link {
  display: contents;
}
.navigation-bar-home-icon04 {
  text-decoration: none;
}
.navigation-bar-home-link1 {
  display: contents;
}
.navigation-bar-home-icon06 {
  text-decoration: none;
}
.navigation-bar-home-link2 {
  display: contents;
}
.navigation-bar-home-icon08 {
  text-decoration: none;
}

@media(max-width: 1200px) {
  .navigation-bar-home-links {
    gap: 0;
    justify-content: space-between;
  }
  .navigation-bar-home-link1 {
    margin-left: var(--dl-space-space-twounits);
  }
  .navigation-bar-home-link5 {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .navigation-bar-home-desktop-menu {
    display: none;
  }
  .navigation-bar-home-burger-menu {
    display: flex;
  }
}
@media(max-width: 767px) {
  .navigation-bar-home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navigation-bar-home-burger-menu {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navigation-bar-home-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .navigation-bar-home-image1 {
    height: 1.5rem;
  }
  .navigation-bar-home-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
  .navigation-bar-home-logo {
    height: 2rem;
  }
}
