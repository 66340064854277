.faq-work-with-us-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.faq-work-with-us-column {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.faq-work-with-us-text {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}
.faq-work-with-us-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}
.faq-work-with-us-container {
  align-self: stretch;
}
.faq-work-with-us-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-work-with-us-trigger {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-faq1-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq-work-with-us-icons-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-icon {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-icon02 {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-container03 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.faq-work-with-us-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-faq2-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq-work-with-us-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-icon04 {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-icon06 {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-container06 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-faq3 {
  width: 100%;
  align-items: flex-start;
}
.faq-work-with-us-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-faq2-question1 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.faq-work-with-us-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-icon08 {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-icon10 {
  width: 24px;
  height: 24px;
}
.faq-work-with-us-container09 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.faq-work-with-us-content {
  align-self: stretch;
  align-items: center;
}
.faq-work-with-us-text08 {
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
}
.faq-work-with-us-text09 {
  font-size: 20px;
  text-align: center;
}


@media(max-width: 1600px) {
  .faq-work-with-us-text {
    font-size: 42px;
  }
  .faq-work-with-us-link {
    text-decoration: underline;
  }
  .faq-work-with-us-link1 {
    text-decoration: underline;
  }
  .faq-work-with-us-content {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 1200px) {
  .faq-work-with-us-text {
    font-size: 38px;
  }
}
@media(max-width: 991px) {
  .faq-work-with-us-text {
    font-size: 32px;
  }
  .faq-work-with-us-faq1-question {
    text-align: left;
  }
  .faq-work-with-us-faq2-question {
    text-align: left;
  }
  .faq-work-with-us-faq2-question1 {
    text-align: left;
  }
  .faq-work-with-us-text08 {
    font-size: 24px;
  }
}
@media(max-width: 767px) {
  .faq-work-with-us-faq8 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .faq-work-with-us-faq1-question {
    font-size: 16px;
  }
  .faq-work-with-us-faq2-question {
    font-size: 16px;
  }
  .faq-work-with-us-faq2-question1 {
    font-size: 16px;
  }
  .faq-work-with-us-text08 {
    font-size: 24px;
  }
}
@media(max-width: 479px) {
  .faq-work-with-us-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .faq-work-with-us-text08 {
    font-size: 20px;
  }
  .faq-work-with-us-text09 {
    font-size: 14px;
  }
}
