.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.blog-column {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.blog-column1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.blog-text {
  font-size: 5rem;
  font-style: normal;
  text-align: center;
  font-weight: 900;
}
.blog-text2 {
  color: var(--dl-color-primary-300);
}
.blog-text3 {
  font-size: 24px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}
@media(max-width: 1600px) {
  .blog-column {
    align-items: center;
    justify-content: center;
  }
  .blog-text {
    font-size: 5rem;
    font-style: normal;
    font-weight: 900;
  }
  .blog-text2 {
    color: var(--dl-color-primary-300);
  }
  .blog-text3 {
    font-size: 22px;
  }
}
@media(max-width: 1200px) {
  .blog-text {
    font-size: 4rem;
  }
  .blog-text3 {
    font-size: 20px;
  }
}
@media(max-width: 991px) {
  .blog-text {
    font-size: 3rem;
  }
  .blog-text3 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .blog-text3 {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .blog-text {
    font-size: 2.5rem;
  }
  .blog-text3 {
    font-size: 14px;
  }
}
