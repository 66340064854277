.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-column {
  flex: initial;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-dummy {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  width: 200px;
  display: none;
  object-fit: contain;
}
.home-row {
  flex: initial;
  width: auto;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-column01 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
}
.home-subtitle {
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-stores {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-image01 {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-image02 {
  width: 168px;
  height: 55px;
  margin: var(--dl-space-space-halfunit);
  object-fit: contain;
  text-decoration: none;
}
.home-phone-image {
  flex: 1;
  width: auto;
  height: 500px;
  align-self: center;
  object-fit: contain;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-row01 {
  flex: initial;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-text {
  color: var(--dl-color-custom-neutral-dark);
  padding: 0px;
  align-self: center;
  margin-top: 0px;
}
.home-icon02 {
  width: 42px;
  height: 24px;
}
.home-column02 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text01 {
  font-size: 48px;
  text-transform: uppercase;
}
.home-text02 {
  font-style: normal;
  font-weight: 400;
}
.home-text04 {
  color: var(--dl-color-primary-300);
  font-style: normal;
  font-weight: 700;
}
.home-image03 {
  width: 400px;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  border-radius: 18px;
}
.home-text05 {
  font-size: 20px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  line-height: 1.5;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.home-text07 {
  font-style: normal;
  font-weight: 800;
}
.home-text11 {
  font-style: normal;
  font-weight: 700;
}
.home-row02 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  margin-left: 0px;
  margin-right: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
}
.home-column03 {
  flex: 1;
  width: auto;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-box {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.home-image04 {
  flex: 1;
  width: 200px;
  display: none;
  align-self: center;
  object-fit: cover;
}
.home-text13 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.home-text14 {
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-fourunits);
  text-transform: capitalize;
}
.home-text15 {
  color: var(--dl-color-danger-300);
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-sixunits);
  text-transform: capitalize;
}
.home-li {
  margin-top: var(--dl-space-space-twounits);
}
.home-li1 {
  margin-top: var(--dl-space-space-halfunit);
}
.home-text18 {
  font-size: 32px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.home-text20 {
  text-decoration: line-through;
}
.home-text22 {
  text-decoration: line-through;
}
.home-text25 {
  font-size: 32px;
  align-self: flex-end;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.home-image05 {
  flex: 1;
  width: auto;
  height: 500px;
  align-self: center;
  object-fit: contain;
}
.home-column04 {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  margin-top: 0px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-text28 {
  font-size: 48px;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.home-text29 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 400;
}
.home-text30 {
  color: var(--dl-color-gray-black);
}
.home-text31 {
  color: var(--dl-color-danger-300);
  font-style: normal;
  font-weight: 700;
}
.home-text32 {
  width: 700px;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0px;
}
.home-text35 {
  color: var(--dl-color-success-300);
  font-style: normal;
  font-weight: 700;
}
.home-image06 {
  width: 584px;
  height: 605px;
  object-fit: contain;
}
.home-column05 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text37 {
  font-size: 48px;
  font-weight: 400;
}
.home-text38 {
  font-style: normal;
}
.home-text39 {
  color: #009393;
  font-weight: 700;
}
.home-image07 {
  width: 400px;
  box-shadow: 0px 10px 10px 0px #d4d4d4;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  border-radius: 35px;
}
.home-text40 {
  font-size: 20px;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  line-height: 1.5;
  margin-left: 0px;
  margin-right: 0px;
}
.home-text42 {
  font-style: normal;
  font-weight: 600;
}
.home-text44 {
  color: var(--dl-color-danger-300);
  font-style: normal;
  font-weight: 600;
}
.home-text46 {
  font-style: normal;
  font-weight: 600;
}
.home-text48 {
  color: var(--dl-color-primary-100);
  font-style: normal;
  font-weight: 600;
}
.home-row03 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: 192px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
}
.home-column06 {
  flex: 1;
  width: 833px;
  margin: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-box1 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.home-image08 {
  flex: 1;
  width: 200px;
  display: none;
  align-self: center;
  object-fit: cover;
}
.home-text49 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.home-text50 {
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-fourunits);
  text-transform: capitalize;
}
.home-text51 {
  color: var(--dl-color-danger-300);
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-sixunits);
  text-transform: capitalize;
}
.home-li2 {
  margin-top: var(--dl-space-space-twounits);
}
.home-text52 {
  font-size: 20px;
}
.home-li3 {
  margin-top: var(--dl-space-space-halfunit);
}
.home-text53 {
  font-size: 20px;
}
.home-li4 {
  margin-top: var(--dl-space-space-halfunit);
}
.home-text54 {
  font-size: 20px;
}
.home-text55 {
  font-size: 32px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 600;
}
.home-text58 {
  font-size: 32px;
  align-self: flex-end;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.home-image09 {
  flex: 1;
  width: auto;
  height: 500px;
  align-self: center;
  object-fit: contain;
}
.home-column07 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: 111px;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text61 {
  color: rgb(255, 255, 255);
  font-size: 48px;
  font-weight: 400;
}
.home-text62 {
  color: var(--dl-color-gray-black);
  font-style: normal;
}
.home-text63 {
  color: var(--dl-color-primary-300);
  font-weight: 700;
}
.home-text64 {
  color: var(--dl-color-primary-300);
  font-weight: 700;
}
.home-text65 {
  color: var(--dl-color-primary-300);
  font-weight: 700;
}
.home-text66 {
  width: 700px;
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  font-weight: 500;
  line-height: 1.5;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
}
.home-row04 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  justify-content: center;
}
.home-row05 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-image10 {
  width: auto;
  height: 500px;
  object-fit: cover;
}
.home-image11 {
  width: auto;
  height: 500px;
  object-fit: cover;
}
.home-column08 {
  gap: 0;
  flex: 1;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text67 {
  color: var(--dl-color-danger-300);
  font-size: 28px;
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
}
.home-text68 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 150px;
  align-self: stretch;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  font-family: "Arial Black";
  font-weight: 900;
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text69 {
  align-self: stretch;
  text-align: center;
  line-height: 1.75;
}
.home-text70 {
  font-size: 24px;
}
.home-text71 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.home-text72 {
  font-size: 24px;
}
.home-text74 {
  font-size: 20px;
}
.home-text75 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.home-text76 {
  font-size: 20px;
}
.home-text79 {
  font-style: normal;
  font-weight: 700;
}
.home-column09 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: 0px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
  background-color: #191c1f;
}
.home-column10 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.home-text81 {
  color: rgb(255, 255, 255);
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-sixunits);
}
.home-row06 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.home-link2 {
  display: contents;
}
.home-column11 {
  flex: 1;
  width: 500px;
  height: 270px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-column11:hover {
  transform: scale(1.05);
}
.home-column12 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row07 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-icon04 {
  width: 25px;
  height: 25px;
}
.home-text82 {
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
}
.home-text83 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-transform: capitalize;
}
.home-text84 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  line-height: 1.5;
}
.home-icon06 {
  width: 35px;
  height: 35px;
  align-self: flex-end;
}
.home-navlink {
  display: contents;
}
.home-column13 {
  flex: 1;
  width: 500px;
  height: 270px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-column13:hover {
  transform: scale(1.05);
}
.home-column14 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row08 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-icon08 {
  width: 35px;
  height: 35px;
}
.home-text85 {
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
}
.home-text86 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-transform: capitalize;
}
.home-text87 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  line-height: 1.5;
}
.home-icon10 {
  width: 35px;
  height: 35px;
  align-self: flex-end;
}
.home-navlink1 {
  display: contents;
}
.home-column15 {
  flex: 1;
  width: 500px;
  height: 270px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-column15:hover {
  transform: scale(1.05);
}
.home-column16 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row09 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-icon12 {
  width: 35px;
  height: 35px;
}
.home-text88 {
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
}
.home-text89 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-transform: capitalize;
}
.home-text90 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  line-height: 1.5;
}
.home-icon14 {
  width: 35px;
  height: 35px;
  align-self: flex-end;
}
.home-row10 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: row;
}
.home-link3 {
  display: contents;
}
.home-column17 {
  flex: 1;
  width: 500px;
  height: 270px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-column17:hover {
  transform: scale(1.05);
}
.home-column18 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row11 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-icon16 {
  width: 35px;
  height: 35px;
}
.home-text91 {
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
}
.home-text92 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-transform: capitalize;
}
.home-text93 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  line-height: 1.5;
}
.home-icon19 {
  width: 35px;
  height: 35px;
  align-self: flex-end;
}
.home-navlink2 {
  display: contents;
}
.home-column19 {
  flex: 1;
  width: 500px;
  height: 270px;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-column19:hover {
  transform: scale(1.05);
}
.home-column20 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row12 {
  gap: var(--dl-space-space-oneandhalfunits);
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-icon21 {
  width: 35px;
  height: 35px;
}
.home-text94 {
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
}
.home-text95 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-transform: capitalize;
}
.home-text96 {
  color: var(--dl-color-gray-500);
  font-size: 14px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  line-height: 1.5;
}
.home-icon23 {
  width: 35px;
  height: 35px;
  align-self: flex-end;
}
.home-text97 {
  fill: #8d969e;
  color: #8d969e;
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-weight: 500;
}
.home-text98 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 38px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 500;
}
.home-row13 {
  gap: var(--dl-space-space-sixunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  justify-content: flex-start;
}
.home-image12 {
  width: 100px;
  object-fit: cover;
}
.home-navlink3 {
  display: contents;
}
.home-image13 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
.home-navlink4 {
  display: contents;
}
.home-image14 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
.home-navlink5 {
  display: contents;
}
.home-image15 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .home-column {
    padding: 0px;
  }
  .home-column01 {
    padding-right: var(--dl-space-space-sixunits);
  }
  .home-title {
    font-size: 48px;
  }
  .home-phone-image {
    width: 514px;
  }
  .home-column02 {
    margin-top: 0px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-image03 {
    flex: 1;
    width: 400px;
    align-self: center;
    margin-top: var(--dl-space-space-twounits);
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    border-radius: 18px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text05 {
    margin-top: 0px;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-column04 {
    padding: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-column05 {
    padding: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-column07 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-text66 {
    width: 700px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .home-column09 {
    height: auto;
    padding: var(--dl-space-space-sixunits);
    justify-content: flex-start;
  }
}
@media(max-width: 1200px) {
  .home-column {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-row {
    padding: 0px;
  }
  .home-column01 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-title {
    font-size: 38px;
  }
  .home-subtitle {
    font-size: 16px;
  }
  .home-phone-image {
    width: 482px;
    height: 400px;
  }
  .home-column02 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text01 {
    font-size: 38px;
  }
  .home-image03 {
    width: 300px;
  }
  .home-row02 {
    margin-top: var(--dl-space-space-fourunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-column03 {
    width: 730px;
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-text13 {
    font-size: 38px;
  }
  .home-text14 {
    font-size: 38px;
  }
  .home-text15 {
    font-size: 38px;
  }
  .home-text28 {
    font-size: 38px;
  }
  .home-image06 {
    width: 500px;
  }
  .home-text37 {
    font-size: 38px;
  }
  .home-row03 {
    margin-top: 192px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-column06 {
    width: 730px;
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-text49 {
    font-size: 38px;
  }
  .home-text50 {
    font-size: 38px;
  }
  .home-text51 {
    font-size: 38px;
  }
  .home-text55 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
  }
  .home-text58 {
    font-size: 24px;
  }
  .home-text61 {
    font-size: 38px;
  }
  .home-text66 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
  }
  .home-column08 {
    width: 730px;
    padding-left: 0px;
  }
  .home-text67 {
    font-size: 22px;
  }
  .home-text68 {
    font-size: 80px;
  }
  .home-text69 {
    align-self: stretch;
    line-height: 1.75;
  }
  .home-text70 {
    font-size: 22px;
  }
  .home-text71 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text72 {
    font-size: 22px;
  }
  .home-text73 {
    font-size: 22px;
  }
  .home-text74 {
    font-size: 18px;
  }
  .home-text75 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text76 {
    font-size: 18px;
  }
  .home-text77 {
    font-size: 18px;
  }
  .home-text78 {
    font-size: 14px;
  }
  .home-text79 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text80 {
    font-size: 14px;
  }
  .home-column09 {
    height: auto;
  }
  .home-text81 {
    font-size: 38px;
  }
  .home-column11 {
    height: 300px;
  }
  .home-text83 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .home-column13 {
    height: 300px;
  }
  .home-text86 {
    font-size: 16px;
  }
  .home-column15 {
    height: 300px;
  }
  .home-text89 {
    font-size: 16px;
  }
  .home-column17 {
    height: 300px;
  }
  .home-text92 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .home-column19 {
    height: 300px;
  }
  .home-text95 {
    font-size: 16px;
  }
  .home-text97 {
    font-size: 16px;
  }
  .home-text98 {
    font-size: 28px;
  }
}
@media(max-width: 991px) {
  .home-column {
    flex-wrap: nowrap;
    height: auto;
  }
  .home-image {
    flex: 1;
    width: 500px;
    height: auto;
    display: flex;
    border-radius: 18px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .home-column01 {
    flex: 1;
    padding-top: 0px;
  }
  .home-title {
    font-size: 42px;
    align-self: flex-start;
    text-align: center;
  }
  .home-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .home-stores {
    align-self: center;
    align-items: center;
    flex-direction: row;
  }
  .home-phone-image {
    flex: 1;
    height: 100%;
    display: none;
  }
  .home-column02 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image03 {
    width: 300px;
  }
  .home-row02 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-column03 {
    width: 558px;
    align-self: center;
    align-items: flex-start;
    padding-left: 0px;
    justify-content: center;
  }
  .home-image04 {
    width: 267px;
    height: 1650px;
    display: flex;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text13 {
    font-size: 42px;
    text-align: left;
  }
  .home-text14 {
    font-size: 42px;
    align-self: flex-start;
    text-align: justify;
  }
  .home-text15 {
    font-size: 42px;
    align-self: flex-start;
    text-align: justify;
  }
  .home-text16 {
    text-align: left;
  }
  .home-text17 {
    text-align: left;
  }
  .home-text25 {
    align-self: flex-end;
  }
  .home-image05 {
    display: none;
  }
  .home-column04 {
    flex: 1;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text28 {
    font-size: 42px;
  }
  .home-column05 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text37 {
    font-size: 42px;
    text-align: center;
  }
  .home-row03 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-column06 {
    width: 558px;
    align-self: center;
    align-items: flex-start;
    padding-left: 0px;
    justify-content: center;
  }
  .home-image08 {
    width: 267px;
    height: 1650px;
    display: flex;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text49 {
    font-size: 32px;
    text-align: left;
  }
  .home-text50 {
    font-size: 32px;
    align-self: flex-start;
    text-align: justify;
  }
  .home-text51 {
    font-size: 32px;
    align-self: flex-start;
    text-align: justify;
  }
  .home-text52 {
    text-align: left;
  }
  .home-text53 {
    text-align: left;
  }
  .home-text54 {
    text-align: left;
  }
  .home-text55 {
    font-style: normal;
    text-align: left;
    font-weight: 600;
  }
  .home-text58 {
    align-self: flex-end;
  }
  .home-image09 {
    display: none;
  }
  .home-column07 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text61 {
    font-size: 42px;
    text-align: center;
  }
  .home-row04 {
    position: relative;
    flex-direction: column;
  }
  .home-column08 {
    flex: initial;
    width: auto;
    align-self: center;
    align-items: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 99px;
    justify-content: center;
  }
  .home-column09 {
    flex: 1;
    height: auto;
    padding: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-text81 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-row06 {
    flex: 1;
    flex-direction: column;
  }
  .home-column11 {
    width: 100%;
  }
  .home-text83 {
    font-size: 18px;
  }
  .home-column13 {
    width: 100%;
  }
  .home-text86 {
    font-size: 18px;
  }
  .home-column15 {
    width: 100%;
  }
  .home-text89 {
    font-size: 18px;
  }
  .home-row10 {
    flex: 1;
    flex-direction: column;
  }
  .home-column17 {
    width: 100%;
  }
  .home-text92 {
    font-size: 18px;
  }
  .home-column19 {
    width: 100%;
  }
  .home-text95 {
    font-size: 18px;
  }
  .home-text97 {
    font-size: 18px;
  }
  .home-text98 {
    font-size: 32px;
  }
  .home-row13 {
    gap: 0;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .home-column {
    flex: 1;
    flex-wrap: wrap;
    align-self: center;
    flex-direction: column;
  }
  .home-image {
    flex: 1;
    width: 450px;
    height: auto;
    flex-wrap: wrap;
    align-self: center;
  }
  .home-column01 {
    padding: 0px;
  }
  .home-title {
    font-size: 32px;
  }
  .home-stores {
    align-items: center;
    flex-direction: row;
  }
  .home-column02 {
    flex: 1;
    flex-wrap: wrap;
    align-self: center;
    margin-right: 0px;
    flex-direction: column;
  }
  .home-text01 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .home-text04 {
    color: var(--dl-color-primary-300);
    font-style: normal;
    font-weight: 700;
  }
  .home-image03 {
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text05 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-row02 {
    flex: 1;
    flex-direction: row;
  }
  .home-column03 {
    flex: 1;
    width: auto;
    align-items: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-start;
  }
  .home-image04 {
    width: 214px;
    height: 433px;
  }
  .home-text13 {
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text14 {
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text15 {
    color: var(--dl-color-danger-300);
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text16 {
    text-align: left;
  }
  .home-text18 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
  }
  .home-text19 {
    font-size: 24px;
  }
  .home-text20 {
    font-size: 24px;
    text-decoration: line-through;
  }
  .home-text21 {
    font-size: 24px;
  }
  .home-text22 {
    font-size: 24px;
    text-decoration: line-through;
  }
  .home-text23 {
    font-size: 24px;
  }
  .home-text25 {
    font-size: 24px;
    align-self: flex-end;
    font-style: normal;
    text-align: right;
    font-weight: 300;
  }
  .home-text26 {
    font-size: 24px;
  }
  .home-image05 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-column04 {
    flex: 1;
    flex-wrap: wrap;
    align-self: center;
    margin-right: 0px;
    flex-direction: column;
  }
  .home-text28 {
    font-size: 32px;
    text-align: center;
  }
  .home-text32 {
    width: 100%;
  }
  .home-image06 {
    width: 100%;
  }
  .home-column05 {
    flex: 1;
    flex-wrap: wrap;
    align-self: center;
    margin-right: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-text37 {
    font-size: 32px;
  }
  .home-text40 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-row03 {
    flex: 1;
    flex-direction: row;
  }
  .home-column06 {
    flex: 1;
    width: auto;
    align-items: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-start;
  }
  .home-image08 {
    width: 214px;
    height: 433px;
  }
  .home-text49 {
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text50 {
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text51 {
    color: var(--dl-color-danger-300);
    font-size: 32px;
    text-transform: capitalize;
  }
  .home-text52 {
    text-align: left;
  }
  .home-text55 {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
  }
  .home-text58 {
    font-size: 24px;
    align-self: flex-end;
    font-style: normal;
    text-align: right;
    font-weight: 300;
  }
  .home-text59 {
    font-size: 24px;
  }
  .home-image09 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-column07 {
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
    align-items: center;
    margin-left: 0px;
    padding-top: var(--dl-space-space-threeunits);
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-text61 {
    font-size: 32px;
    align-self: center;
  }
  .home-text66 {
    width: auto;
  }
  .home-row04 {
    flex: initial;
    width: auto;
    align-self: center;
  }
  .home-row05 {
    flex-direction: column;
  }
  .home-image11 {
    display: none;
  }
  .home-column08 {
    flex: initial;
    width: 100%;
    align-items: center;
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .home-column09 {
    flex: 1;
    height: auto;
    margin: 0px;
    padding: var(--dl-space-space-threeunits);
    align-self: center;
    flex-direction: column;
  }
  .home-text81 {
    font-size: 32px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text97 {
    align-self: center;
  }
  .home-row13 {
    gap: var(--dl-space-space-unit);
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .home-column {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .home-image {
    width: 100%;
  }
  .home-column01 {
    height: 796px;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
  }
  .home-title {
    font-size: 24px;
    align-self: flex-start;
    text-align: center;
  }
  .home-subtitle {
    font-size: 12px;
    text-align: center;
  }
  .home-stores {
    align-items: center;
    flex-direction: row;
  }
  .home-image01 {
    width: 150px;
  }
  .home-image02 {
    width: 125px;
  }
  .home-text {
    text-align: center;
    font-size: 12px;
  }
  .home-column02 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text01 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .home-text04 {
    color: var(--dl-color-primary-300);
    font-style: normal;
    font-weight: 700;
  }
  .home-text05 {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-row02 {
    flex-direction: column;
  }
  .home-column03 {
    flex: 1;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-image04 {
    flex: 1;
    width: 199px;
    height: 396px;
    margin: var(--dl-space-space-twounits);
    display: flex;
    align-self: center;
  }
  .home-text13 {
    font-size: 24px;
  }
  .home-text14 {
    font-size: 24px;
  }
  .home-text15 {
    font-size: 24px;
  }
  .home-text18 {
    font-size: 20px;
  }
  .home-text25 {
    font-size: 20px;
  }
  .home-image05 {
    display: none;
  }
  .home-column04 {
    width: auto;
    height: 665px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
  }
  .home-text28 {
    font-size: 24px;
  }
  .home-text32 {
    width: auto;
    font-size: 14px;
  }
  .home-image06 {
    width: 300px;
    height: 336px;
  }
  .home-column05 {
    flex: 1;
    height: auto;
    padding: var(--dl-space-space-unit);
    flex-wrap: wrap;
    flex-direction: column;
  }
  .home-text37 {
    font-size: 24px;
  }
  .home-image07 {
    width: 200px;
    border-radius: 18px;
  }
  .home-text40 {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text42 {
    font-style: normal;
    font-weight: 600;
  }
  .home-text44 {
    color: var(--dl-color-danger-300);
    font-style: normal;
    font-weight: 600;
  }
  .home-text46 {
    font-style: normal;
    font-weight: 600;
  }
  .home-text48 {
    color: var(--dl-color-primary-100);
    font-style: normal;
    font-weight: 600;
  }
  .home-row03 {
    margin-top: var(--dl-space-space-sixunits);
    flex-direction: column;
  }
  .home-column06 {
    flex: 1;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-box1 {
    width: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .home-image08 {
    flex: 1;
    width: 199px;
    height: 396px;
    margin: var(--dl-space-space-twounits);
    display: flex;
    align-self: center;
  }
  .home-text49 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }
  .home-text50 {
    font-size: 24px;
  }
  .home-text51 {
    font-size: 24px;
  }
  .home-text55 {
    font-size: 20px;
  }
  .home-text58 {
    font-size: 20px;
  }
  .home-image09 {
    display: none;
  }
  .home-column07 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text61 {
    font-size: 24px;
  }
  .home-text66 {
    font-size: 20px;
  }
  .home-row04 {
    flex: 1;
  }
  .home-image10 {
    width: 200px;
    height: auto;
  }
  .home-column08 {
    flex: 1;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text67 {
    color: var(--dl-color-danger-300);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
  .home-text69 {
    line-height: 1.75;
  }
  .home-text70 {
    font-size: 22px;
  }
  .home-text71 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text72 {
    font-size: 22px;
  }
  .home-text73 {
    font-size: 22px;
  }
  .home-text74 {
    font-size: 18px;
  }
  .home-text75 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text76 {
    font-size: 18px;
  }
  .home-text77 {
    font-size: 18px;
  }
  .home-text78 {
    font-size: 14px;
  }
  .home-text79 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  .home-text80 {
    font-size: 14px;
  }
  .home-column09 {
    width: auto;
    height: auto;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text81 {
    font-size: 28px;
    text-align: center;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text97 {
    font-size: 16px;
  }
  .home-text98 {
    font-size: 24px;
    text-align: center;
  }
  .home-image12 {
    width: 75px;
  }
  .home-image13 {
    width: 75px;
  }
  .home-image14 {
    width: 75px;
  }
  .home-image15 {
    width: 75px;
  }
}
