.work-with-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/become_agent.png");
  background-repeat: no-repeat;
}
.work-with-us-column {
  width: 100%;
  height: 100vh;
  display: flex;
  max-width: 100%;
  align-self: center;
  max-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.work-with-us-dummy {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.work-with-us-column01 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.work-with-us-text {
  width: 850px;
  font-size: 5rem;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 900;
  line-height: 1.5;
  text-transform: uppercase;
}
.work-with-us-row {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  justify-content: center;
}
.work-with-us-icon {
  fill: var(--dl-color-gray-black);
  width: 24px;
  height: 24px;
}
.work-with-us-text01 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.work-with-us-text02 {
  font-style: normal;
}
.work-with-us-text03 {
  color: var(--dl-color-primary-300);
  font-weight: 700;
}
.work-with-us-text04 {
  font-style: normal;
}
.work-with-us-icon02 {
  fill: var(--dl-color-gray-black);
  width: 24px;
  height: 24px;
}
.work-with-us-row01 {
  flex: initial;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.work-with-us-icon04 {
  width: 42px;
  height: 24px;
}
.work-with-us-text05 {
  color: var(--dl-color-custom-neutral-dark);
  padding: 0px;
  align-self: center;
  margin-top: 0px;
  text-align: center;
}
.work-with-us-icon06 {
  width: 42px;
  height: 24px;
}
.work-with-us-column02 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.work-with-us-text06 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.work-with-us-text09 {
  font-size: 20px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  line-height: 1.5;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
}
.work-with-us-text11 {
  font-style: normal;
  font-weight: 700;
}
.work-with-us-text15 {
  font-style: normal;
  font-weight: 700;
}
.work-with-us-row02 {
  width: 100%;
  height: 450px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.work-with-us-image {
  flex: initial;
  width: 544px;
  height: 344px;
  max-width: auto;
  align-self: center;
  object-fit: contain;
}
.work-with-us-image1 {
  flex: initial;
  width: 562px;
  height: auto;
  max-width: auto;
  object-fit: contain;
}
.work-with-us-column03 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.work-with-us-image2 {
  width: 150;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-threeunits);
}
.work-with-us-text17 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.work-with-us-text20 {
  margin: var(--dl-space-space-twounits);
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
}
.work-with-us-column04 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: 102px;
  justify-content: flex-start;
  background-color: rgba(231, 228, 228, 0.19);
}
.work-with-us-row03 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}
.work-with-us-text23 {
  padding: var(--dl-space-space-halfunit);
  font-size: 48px;
  align-self: flex-start;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-color: var(--dl-color-theme-accent1);
}
.work-with-us-column05 {
  flex: 0 0 auto;
  width: auto;
  height: 85px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text24 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  padding: 0px;
  font-size: 48px;
  font-style: normal;
  text-align: center;
  font-family: "Raleway";
  font-weight: 500;
  line-height: 0.9;
  text-transform: uppercase;
}
.work-with-us-text25 {
  fill: var(--dl-color-danger-300);
  color: var(--dl-color-danger-300);
  font-style: normal;
  text-align: center;
  font-weight: 400;
}
.work-with-us-row04 {
  gap: 192px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: center;
}
.work-with-us-image3 {
  flex: initial;
  width: auto;
  height: 289px;
  object-fit: cover;
}
.work-with-us-column06 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 373px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text26 {
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.work-with-us-text27 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.work-with-us-row05 {
  gap: 192px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: center;
}
.work-with-us-column07 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 373px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text28 {
  font-size: 42px;
  text-transform: uppercase;
}
.work-with-us-text29 {
  font-size: 18px;
}
.work-with-us-image4 {
  flex: initial;
  width: auto;
  height: 289px;
  object-fit: cover;
}
.work-with-us-column08 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 373px;
  height: auto;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text30 {
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.work-with-us-text31 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.work-with-us-row06 {
  gap: 192px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: center;
}
.work-with-us-image5 {
  flex: initial;
  width: auto;
  height: 289px;
  object-fit: cover;
}
.work-with-us-column09 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 373px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text32 {
  font-size: 42px;
  text-transform: uppercase;
}
.work-with-us-text33 {
  font-size: 18px;
}
.work-with-us-row07 {
  gap: 192px;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: center;
}
.work-with-us-column10 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 468px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text34 {
  font-size: 42px;
  text-transform: uppercase;
}
.work-with-us-text35 {
  font-size: 18px;
}
.work-with-us-image6 {
  flex: initial;
  width: auto;
  height: 289px;
  object-fit: cover;
}
.work-with-us-column11 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 373px;
  height: auto;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-text36 {
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.work-with-us-text37 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.work-with-us-column12 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 192px;
  padding-right: 192px;
  flex-direction: column;
  padding-bottom: 107px;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.work-with-us-row08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  justify-content: center;
}
.work-with-us-text38 {
  padding: var(--dl-space-space-halfunit);
  font-size: 48px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-color: var(--dl-color-theme-accent1);
}
.work-with-us-text39 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  padding: var(--dl-space-space-unit);
  font-size: 48px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 500;
  text-transform: uppercase;
}
.work-with-us-row09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: space-between;
}
.work-with-us-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-icon08 {
  fill: #D9D9D9;
  width: 75px;
  height: 75px;
}
.work-with-us-text40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.work-with-us-text41 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 500px;
  text-align: center;
  line-height: 1.5;
}
.work-with-us-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.work-with-us-icon10 {
  fill: #D9D9D9;
  width: 75px;
  height: 75px;
}
.work-with-us-text42 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
}
.work-with-us-text43 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 500px;
  text-align: center;
  line-height: 1.5;
}
@media(max-width: 1600px) {
  .work-with-us-column02 {
    padding: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .work-with-us-text09 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .work-with-us-column03 {
    padding: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .work-with-us-image2 {
    width: 150px;
  }
  .work-with-us-column04 {
    padding: var(--dl-space-space-threeunits);
    padding-bottom: 119px;
    justify-content: flex-start;
  }
  .work-with-us-row04 {
    gap: var(--dl-space-space-sixunits);
  }
  .work-with-us-image3 {
    height: 250px;
  }
  .work-with-us-row05 {
    gap: var(--dl-space-space-sixunits);
  }
  .work-with-us-image4 {
    height: 250px;
  }
  .work-with-us-row06 {
    gap: var(--dl-space-space-sixunits);
  }
  .work-with-us-image5 {
    height: 250px;
  }
  .work-with-us-row07 {
    gap: var(--dl-space-space-sixunits);
  }
  .work-with-us-image6 {
    height: 250px;
  }
  .work-with-us-column12 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: flex-start;
  }
}
@media(max-width: 1200px) {
  .work-with-us-text {
    font-size: 4rem;
  }
  .work-with-us-text06 {
    font-size: 38px;
  }
  .work-with-us-text07 {
    font-size: 38px;
  }
  .work-with-us-text09 {
    font-size: 20px;
    line-height: 1.5;
  }
  .work-with-us-text11 {
    font-style: normal;
    font-weight: 700;
  }
  .work-with-us-text15 {
    font-style: normal;
    font-weight: 700;
  }
  .work-with-us-row02 {
    gap: var(--dl-space-space-threeunits);
    flex: 1;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    justify-content: center;
  }
  .work-with-us-image {
    width: 400px;
    height: 300px;
  }
  .work-with-us-image1 {
    width: 400px;
    height: 300px;
  }
  .work-with-us-image2 {
    width: 125px;
  }
  .work-with-us-text17 {
    font-size: 38px;
  }
  .work-with-us-column04 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .work-with-us-text23 {
    font-size: 34px;
  }
  .work-with-us-column05 {
    height: 73px;
  }
  .work-with-us-text24 {
    font-size: 34px;
  }
  .work-with-us-text25 {
    font-size: 14px;
  }
  .work-with-us-text26 {
    font-size: 32px;
  }
  .work-with-us-text27 {
    font-size: 16px;
  }
  .work-with-us-text28 {
    font-size: 32px;
  }
  .work-with-us-text29 {
    font-size: 16px;
  }
  .work-with-us-text30 {
    font-size: 32px;
  }
  .work-with-us-text31 {
    font-size: 16px;
  }
  .work-with-us-text32 {
    font-size: 32px;
  }
  .work-with-us-text33 {
    font-size: 16px;
  }
  .work-with-us-text34 {
    font-size: 32px;
  }
  .work-with-us-text35 {
    font-size: 16px;
  }
  .work-with-us-text36 {
    font-size: 32px;
  }
  .work-with-us-text37 {
    font-size: 16px;
  }
  .work-with-us-text38 {
    font-size: 34px;
  }
  .work-with-us-text39 {
    font-size: 34px;
  }
}
@media(max-width: 991px) {
  .work-with-us-column02 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-row02 {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .work-with-us-image {
    width: auto;
    max-width: 300px;
  }
  .work-with-us-image1 {
    width: auto;
    height: auto;
    max-width: 300px;
  }
  .work-with-us-column03 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-column04 {
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-row03 {
    width: auto;
  }
  .work-with-us-image3 {
    flex: 1;
    height: 200px;
  }
  .work-with-us-column06 {
    flex: 1;
    width: auto;
  }
  .work-with-us-text26 {
    font-size: 28px;
  }
  .work-with-us-text27 {
    font-size: 16px;
  }
  .work-with-us-column07 {
    flex: 1;
    width: auto;
  }
  .work-with-us-text28 {
    font-size: 28px;
  }
  .work-with-us-text29 {
    font-size: 16px;
  }
  .work-with-us-image4 {
    flex: 1;
    height: 200px;
  }
  .work-with-us-text30 {
    font-size: 34px;
  }
  .work-with-us-text31 {
    font-size: 16px;
  }
  .work-with-us-image5 {
    flex: 1;
    height: 200px;
  }
  .work-with-us-column09 {
    flex: 1;
    width: auto;
  }
  .work-with-us-text32 {
    font-size: 28px;
  }
  .work-with-us-text33 {
    font-size: 16px;
  }
  .work-with-us-column10 {
    flex: 1;
    width: auto;
  }
  .work-with-us-text34 {
    font-size: 28px;
  }
  .work-with-us-text35 {
    font-size: 16px;
  }
  .work-with-us-image6 {
    flex: 1;
    height: 200px;
  }
  .work-with-us-text36 {
    font-size: 34px;
  }
  .work-with-us-text37 {
    font-size: 16px;
  }
  .work-with-us-column12 {
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-row09 {
    gap: var(--dl-space-space-sixunits);
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .work-with-us-column {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .work-with-us-text {
    width: 100%;
    font-size: 2.5rem;
  }
  .work-with-us-row {
    gap: 4px;
  }
  .work-with-us-column02 {
    flex: 1;
    flex-wrap: wrap;
    align-self: center;
    margin-right: 0px;
    flex-direction: column;
  }
  .work-with-us-text06 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-text07 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-row02 {
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .work-with-us-column03 {
    flex: initial;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-image2 {
    width: 75px;
  }
  .work-with-us-text17 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-text20 {
    width: auto;
    font-size: 16px;
  }
  .work-with-us-column04 {
    flex: initial;
    height: auto;
    align-items: center;
    margin-right: 0px;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-text24 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-row04 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    height: auto;
    align-items: flex-start;
    flex-direction: column;
  }
  .work-with-us-image3 {
    width: 100%;
    height: auto;
  }
  .work-with-us-column06 {
    flex: 0 0 auto;
    width: 100%;
  }
  .work-with-us-text27 {
    width: 100%;
  }
  .work-with-us-row05 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .work-with-us-column07 {
    display: none;
  }
  .work-with-us-image4 {
    flex: 1;
    width: 100%;
  }
  .work-with-us-column08 {
    width: 100%;
    display: flex;
  }
  .work-with-us-text30 {
    font-size: 28px;
  }
  .work-with-us-text31 {
    width: 100%;
  }
  .work-with-us-row06 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    height: auto;
    align-items: flex-start;
    flex-direction: column;
  }
  .work-with-us-image5 {
    width: 100%;
    height: auto;
  }
  .work-with-us-column09 {
    flex: 0 0 auto;
    width: 100%;
  }
  .work-with-us-text33 {
    width: 100%;
  }
  .work-with-us-row07 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .work-with-us-column10 {
    display: none;
  }
  .work-with-us-image6 {
    flex: 1;
    width: 100%;
  }
  .work-with-us-column11 {
    width: 100%;
    display: flex;
  }
  .work-with-us-text36 {
    font-size: 28px;
  }
  .work-with-us-text37 {
    width: 100%;
  }
  .work-with-us-column12 {
    flex: initial;
    height: auto;
    align-items: center;
    margin-right: 0px;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-text39 {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-row09 {
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .work-with-us-text41 {
    width: 400px;
  }
  .work-with-us-text43 {
    width: 400px;
  }
}
@media(max-width: 479px) {
  .work-with-us-text {
    width: auto;
    font-size: 2rem;
  }
  .work-with-us-row {
    align-items: center;
  }
  .work-with-us-text01 {
    font-size: 14px;
  }
  .work-with-us-icon04 {
    width: 24px;
  }
  .work-with-us-text05 {
    font-size: 12px;
  }
  .work-with-us-icon06 {
    width: 24px;
  }
  .work-with-us-column02 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .work-with-us-text06 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-text07 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-text09 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .work-with-us-row02 {
    flex-direction: row;
  }
  .work-with-us-image {
    width: auto;
    height: 150px;
  }
  .work-with-us-image1 {
    width: auto;
    height: 150px;
    padding-right: 0px;
  }
  .work-with-us-column03 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .work-with-us-image2 {
    width: 75px;
  }
  .work-with-us-text17 {
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
  .work-with-us-text20 {
    width: auto;
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .work-with-us-column04 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .work-with-us-text24 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .work-with-us-text26 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
  .work-with-us-column12 {
    flex: 1;
    height: auto;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .work-with-us-text39 {
    font-size: 24px;
    text-align: center;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    text-transform: uppercase;
  }
  .work-with-us-row09 {
    flex-direction: column;
  }
  .work-with-us-container1 {
    width: 100%;
  }
  .work-with-us-text41 {
    width: 100%;
  }
  .work-with-us-container2 {
    width: 100%;
  }
  .work-with-us-text43 {
    width: 100%;
  }
}
