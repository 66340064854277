.terms-of-service-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.terms-of-service-column {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.terms-of-service-text {
  font-size: 22px;
}
.terms-of-service-text01 {
  font-size: 48px;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
}
.terms-of-service-text02 {
  width: auto;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  line-height: 1.75;
}
.terms-of-service-text06 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text23 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text29 {
  font-size: 28px;
  font-weight: 700;
}
.terms-of-service-text35 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text41 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text47 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text53 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text59 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text65 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text71 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text77 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.terms-of-service-text86 {
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
}
@media(max-width: 1200px) {
  .terms-of-service-column {
    margin-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-sixunits);
    padding-right: var(--dl-space-space-sixunits);
  }
  .terms-of-service-text {
    font-size: 20px;
  }
  .terms-of-service-text01 {
    font-size: 38px;
  }
}
@media(max-width: 991px) {
  .terms-of-service-column {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .terms-of-service-text {
    font-size: 18px;
  }
  .terms-of-service-text01 {
    font-size: 32px;
  }
}
@media(max-width: 767px) {
  .terms-of-service-column {
    padding: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .terms-of-service-column {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .terms-of-service-text {
    font-size: 16px;
  }
  .terms-of-service-text01 {
    font-size: 24px;
  }
  .terms-of-service-text02 {
    line-height: 1.75;
  }
  .terms-of-service-text06 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text23 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text24 {
    font-size: 28px;
  }
  .terms-of-service-text29 {
    font-size: 28px;
    font-weight: 700;
  }
  .terms-of-service-text30 {
    font-size: 28px;
  }
  .terms-of-service-text35 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text36 {
    font-size: 28px;
  }
  .terms-of-service-text41 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text42 {
    font-size: 28px;
  }
  .terms-of-service-text47 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text48 {
    font-size: 28px;
  }
  .terms-of-service-text53 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text54 {
    font-size: 28px;
    font-weight: bold;
  }
  .terms-of-service-text59 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text60 {
    font-size: 28px;
  }
  .terms-of-service-text65 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text66 {
    font-size: 28px;
  }
  .terms-of-service-text71 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text72 {
    font-size: 28px;
  }
  .terms-of-service-text77 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }
  .terms-of-service-text78 {
    font-size: 28px;
  }
  .terms-of-service-text86 {
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
  }
}
